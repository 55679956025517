import { Link } from 'gatsby';
import * as React from 'react';

import { KoalaIcon, Layout, SEO } from '../components';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <article className="px-4 py-12 mx-auto bg-gray-200 sm:px-6 lg:px-8 max-w-7xl">
        <KoalaIcon className="mx-auto w-36" />
        <h1 className="text-3xl font-bold text-center text-green">
          404: Page Not Found
        </h1>
        <p className="max-w-md mx-auto mt-2 text-lg font-semibold leading-6 text-center uppercase text-green">
          Sorry, the page you were looking for could not be found.
        </p>
        <div className="text-center">
          <Link
            to="/"
            className="inline-block px-8 py-1 mt-6 font-bold uppercase border-2 rounded-full border-green text-green"
          >
            Return home
          </Link>
        </div>
      </article>
    </Layout>
  );
}

export default NotFoundPage;
